<template>
  <div>
    <table
      cellpadding="10"
      cellspacing="0"
      width="95%"
      class="hover-table invoices-table"
      align="center"
    >
      <thead>
        <tr>
          
          <td @click="() => (sortByField = 'order_date')" valign="middle">
            <strong>{{ $t("ordersTableHeaderDate") }}</strong
            >&nbsp;
            <img
              v-if="pending === false"
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('order_date')"
              :style="
                sortByField === 'order_date' &&
                  reverse === false &&
                  'transform: rotate(180deg)'
              "
            />
          </td>
          <td valign="middle" v-if="company.role === 'FAC'">
            <strong>{{ $t("ordersTableHeaderNumber") }}</strong
            >
          </td>
          <td
            class="priority-0"
            @click="() => (sortByField = 'order_product')"
            valign="middle"
          >
            <strong>{{ $t("ordersTableHeaderProduct") }}</strong
            >&nbsp;
            <img
              v-if="pending === false"
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('order_product')"
              :style="
                sortByField === 'order_product' &&
                  reverse === false &&
                  'transform: rotate(180deg)'
              "
            />
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("ordersTableHeaderType") }}</strong>
            &nbsp;
          </td>
          <td
            class="priority-2"
            @click="() => (sortByField = 'totamtdue')"
            valign="middle"
          >
            <strong>{{ $t("ordersTableHeaderAmount") }}</strong
            >&nbsp;
            <img
              v-if="pending === false"
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('totamtdue')"
              :style="
                sortByField === 'totamtdue' &&
                  reverse === false &&
                  'transform: rotate(180deg)'
              "
            />
          </td>
          <td
            class="priority-3"
            @click="() => (sortByField = 'order_status')"
            valign="middle"
          >
            <strong>{{ $t("ordersTableHeaderStatus") }}</strong
            >&nbsp;
            <img
              v-if="pending === false"
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('order_status')"
              :style="
                sortByField === 'order_status' &&
                  reverse === false &&
                  'transform: rotate(180deg)'
              "
            />
          </td>
          <td v-if="!pending" class="priority-4" valign="middle">
            <strong>{{ $t("ordersTableHeaderAddress") }}</strong
            >&nbsp;
          </td>
          <td
            v-if="flxUserLevel().hasAccess"
            class="priority-5"
            valign="middle"
          >
            <strong>{{ $t("ordersTableHeaderPlatform") }}</strong>
          </td>
          <td v-if="!duplicate" class="priority-5" valign="middle">
            <strong>
              <p
                v-html="
                  pending
                    ? $t('ordersTableHeaderInvoiceOrProform')
                    : $t('ordersTableHeaderInvoice')
                "
              />
              &nbsp;&nbsp;
            </strong>
          </td>
          <td valign="middle" v-if="pending && company.role !== 'FAC'">
            <strong>{{ $t("ordersTableHeaderDeliveryDate") }}</strong>
          </td>
          <td valign="middle" v-if="pending && company.role === 'FAC'">
            <strong><p
                v-html='$t("ordersTableHeaderInvoiceOrProformNumber")'></p> &nbsp;&nbsp;</strong>
          </td>
          <td valign="middle" v-if="!dashboard && pending">
            <strong>{{ $t("beneficiariesTableAction") }}</strong>
          </td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr v-if="this.companyOrders === null">
          <td colspan="6">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(order, index) in companyOrders">
          <tr
            :key="index"
            class="trhover parent"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            <td valign="middle">
              <a
                class="detailsOrderMobile priority-show-5 display-inline-block"
                @click="selectOrderMobile(index)"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  v-if="detailsMobile && order === selectedOrder"
                />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <strong>{{ displayDate(order.date) }}</strong>
            </td>
            <td valign="middle" class="priority-0" v-if=" company.role === 'FAC'">
              <strong>{{ order.number }}</strong>
            </td>
            <td valign="middle" class="priority-0">
              <img
                :src="
                  require('@/assets/images/bullet_' + order.product + '.svg')
                "
              />
              &nbsp;&nbsp;{{ getProductNameMethod(order.product) }}
            </td>
            <td valign="middle" class="priority-1">
              {{ $t('orderType_' + order.type.replace(/\s/g, '')) }}
              <!-- <img
                :src="require('@/assets/images/' + order.type + '.svg')"
                v-b-tooltip.hover
                :title="[[$t('orderType_' + order.type.replace(/\s/g, ''))]]"
              /> -->
            </td>
            <td valign="middle" class="priority-2">
              {{ order.total_amount_value }} {{ $t("currency") }}
            </td>
            <td valign="middle" class="priority-3">
              <span
                class="status-background"
                :class="selectColorStatus(order.status)"
                >{{ $t("orderStatus_" + order.status) }}</span
              >
            </td>
            <td v-if="!pending" valign="middle" class="priority-4">
              <span v-if="order.total_addresses === 1">
                {{ $t("orderSingleDeliveryAddress") }}
              </span>
              <span v-else>{{ $t("orderMultipleDeliveryAddress") }}</span>
            </td>
            <td
              v-if="flxUserLevel().hasAccess && order.user"
              class="priority-5"
              valign="middle"
            >
              <img
                :src="
                  require('@/assets/images/order_user_' +
                    (order.user.startsWith('FLEX') ? 'flex' : 'up') +
                    '.png')
                "
              />
            </td>
            <td v-if="!duplicate" valign="middle" class="priority-5">
              <a
                v-if="
                  order.type !== 'REPL' &&
                    order.type != 'PIN' &&
                    (order.number > 0 || !pending || company.state_owned) &&
                    order.total_amount_value > 0 &&
                    order.status !== 'A' &&
                    order.status !== 'S' &&
                    order.status !== 'Z'
                "
                class="invoice-icon cursor-pointer"
                @click="downloadOrderFile(order)"
                >&nbsp;</a
              >
            </td>
            <td valign="middle" v-if="pending && company.role !== 'FAC'">
              <a
                v-if="order.card || order.pin"
                class="action-button"
                @click="selectOrderMobile(index, true)"
              >
                <span>{{ $t("showDateDeliveryOrderButtonLabel") }}</span>
              </a>
            </td>
            <td valign="middle" v-if="pending && company.role === 'FAC'">
              {{ order.number }} 
            </td>

            <td valign="middle" v-if="!dashboard && pending">
              <a
                v-if="order.type === 'PAPER' && order.status === 'E'"
                class="action-button"
                @click="selectOrderAction(order, 'awb')"
              >
                <span>{{ $t("trackOrderButtonLabel") }}</span>
              </a>
              &nbsp;&nbsp;
              <a
                class="action-button"
                v-if="!duplicate && order.status === 'P'"
                @click="selectOrderAction(order, 'cancel')"
              >
                <span>{{ $t("cancelOrderButtonLabel") }}</span>
              </a>
              &nbsp;&nbsp;
              <a
                class="action-button"
                v-if="pending && company.role === 'FAC' && order.status === 'P' && order.type === 'REPL'"
                @click="selectOrderAction(order, 'urgent')"
              >
                <span>{{ $t("urgentOrderButtonLabel") }}</span>
              </a>
              &nbsp;&nbsp;
            </td>
            <td valign="middle">
              <a
                class="action-button"
                @click="selectOrderAction(order, 'details')"
              >
                <span v-if="!duplicate">{{ $t("details") }}</span>
                <span v-if="duplicate">
                  {{ $t("orderChooseOrderDuplicate") }}
                </span>
              </a>
            </td>
          </tr>
          <tr
            :key="index + companyOrders.length"
            class="child"
            :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && order === selectedOrder"
          >
            <td colspan="12">
              <p v-if="shippingDate" class="text-center">
                <span v-if="order.card">
                  {{ $t("showDateDeliveryOrderCardText") + displayDate(order.card) }}
                </span>
                <br>
                <span v-if="order.pin">
                  {{ $t("showDateDeliveryOrderPinText") + displayDate(order.pin) }}
                </span>
              </p>
              
              <p class="priority-show-1">
                <strong
                  >{{ $t("ordersTableHeaderType") }} : &nbsp;&nbsp;</strong
                >
                <span>
                  <img
                    :src="require('@/assets/images/' + order.type + '.svg')"
                  />
                </span>
              </p>
              <p class="priority-show-2">
                <strong
                  >{{ $t("ordersTableHeaderAmount") }} : &nbsp;&nbsp;</strong
                >
                <span>{{ order.total_amount_value }} {{ $t("currency") }}</span>
              </p>
              <p class="priority-show-3">
                <strong
                  >{{ $t("ordersTableHeaderStatus") }} : &nbsp;&nbsp;</strong
                >
                <span
                  class="status-background"
                  :class="selectColorStatus(order.status)"
                  >{{ $t("orderStatus_" + order.status) }}</span
                >
              </p>
              <p v-if="!pending" class="priority-show-4">
                <strong
                  >{{ $t("ordersTableHeaderAddress") }} : &nbsp;&nbsp;</strong
                >
                <span v-if="order.total_addresses === 1">
                  {{ $t("orderSingleDeliveryAddress") }}
                </span>
                <span v-else>{{ $t("orderMultipleDeliveryAddress") }}</span>
              </p>
              <p v-if="!duplicate" class="priority-show-5">
                <strong>
                  <p
                    v-html="
                      pending
                        ? $t('ordersTableHeaderInvoiceOrProform')
                        : $t('ordersTableHeaderInvoice')
                    "
                  />
                  &nbsp;&nbsp;
                </strong>
                <span>
                  <a
                    v-if="
                      order.type !== 'REPL' &&
                        order.type != 'PIN' &&
                        (order.number > 0 || company.state_owned) &&
                        order.total_amount_value > 0 &&
                        order.status !== 'A' &&
                        order.status !== 'S' &&
                        order.status !== 'Z'
                    "
                    @click="downloadOrderFile(order)"
                    class="invoice-icon"
                    >&nbsp;</a
                  >
                </span>
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div :id="'modal_popup_info' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info' + pending, false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.downloadInvoicesError">
          <p v-html="$t('errorMessage_' + this.downloadInvoicesError.code)"></p>
        </div>
      </div>
    </div>

    <div :id="'modal_popup_loading' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>

    <div id="modal_popup_activateInvoices" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_activateInvoices')"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div class="invoice-scroll">
          <NoElectronicInvoiceTemplate />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utils from "@/plugins/utils.js";
import moment from "moment";
import httpServiceCustomer from "@/http/http-customer.js";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import { VBTooltip } from "bootstrap-vue";
import NoElectronicInvoiceTemplate from "@/assets/components/NoElectronicInvoiceTemplate.vue";

export default {
  name: "OrdersTableDesktop",
  props: {
    companyOrders: Array,
    company: Object,
    pending: Boolean,
    dashboard: Boolean,
    duplicate: Boolean,
    height: Number,
    msg: String
  },
  directive: {
    "v-b-tooltip": VBTooltip
  },
  components: {
    NoElectronicInvoiceTemplate
  },
  data() {
    return {
      detailsMobile: false,
      sortByField: "order_date",
      selectedOrder: null,
      reverse: false,
      downloadInvoicesError: null,
      shippingDate: false,
    };
  },
  methods: {
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
    },
    selectOrderMobile(index, shippingDate = false) {
      if (
        this.selectedOrder !== null &&
        this.selectedOrder.number !== this.companyOrders[index].number
      ) {
        this.detailsMobile = !this.detailsMobile;
      }
      this.selectedOrder = this.companyOrders[index];
      this.detailsMobile = !this.detailsMobile;
      this.shippingDate = shippingDate;
    },
    selectOrderAction(order, action) {
      this.$emit("click", order, action);
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.company.products);
    },
    selectColorStatus(status) {
      return utils.selectColorStatus(status.length > 2 ? "V2" : status);
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    downloadOrderFile(order) {
      let documentType = order.id.substring(0, 3);
      let result = this.company.products.filter(
        x => x.card === order.product || x.paper === order.product
      );

      if (documentType === "100" && result[0].payment_type !== "Z") {
        documentType = "101";
      }
      if (documentType === "101" && this.company.electronic_invoice === false && this.company.role !== "FAC") {
        this.showModalPopup("modal_popup_activateInvoices", true);
        return;
      }

      this.showModalPopup("modal_popup_loading" + this.pending, true);

      let link =
        this.company.company_id +
        "/customer_order_documents/" +
        documentType +
        order.id.substring(documentType.length);
      httpServiceCustomer.customerService
        .getOrderDocumentFile(link, Cookies.get("token"), false)
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.showModalPopup("modal_popup_loading" + this.pending, false);
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.invoice + ".pdf");
            this.downloadInvoicesError = null;
          } else if (response.status == 400 || response.status == 403) {
            this.downloadInvoicesError = { code: response.status };
            this.deliveryAddresses = null;
            this.showModalPopup("modal_popup_loading" + this.pending, false);
            this.showModalPopup("modal_popup_info" + this.pending, true);
          } else if (response.status == 404) {
            this.downloadInvoicesError = { code: response.status };
            this.showModalPopup("modal_popup_loading" + this.pending, false);
            this.showModalPopup("modal_popup_info" + this.pending, true);
          } else {
            this.downloadInvoicesError = { code: "503" };
            this.showModalPopup("modal_popup_loading" + this.pending, false);
            this.showModalPopup("modal_popup_info" + this.pending, true);
          }
        })
        .catch(error => {
          this.downloadInvoicesError = { code: "503" };
          this.deliveryAddresses = null;
          this.showModalPopup("modal_popup_loading" + this.pending, false);
          this.showModalPopup("modal_popup_info" + this.pending, true);
        });
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
@media (max-width: 768px) {
  .orders-page .invoices-filter .vue-daterange-picker {
    width: auto;
  }
}
</style>
