<template>
  <div>
    <p>
      {{ $t("invoiceNoElectronicInvoices") }}
      <a href="mailto:info@upromania.ro">
        <strong>info@upromania.ro</strong>
      </a>
    </p>
    <br />
    <p>
      <a
        class="button-orange m-0"
        href="http://upromania.ro/html/docs/Acord-factura-electronica-07-05-2015.pdf?r=1"
        >{{ $t("invoiceDownloadAgreementForm") }}</a
      >
    </p>
    <br />
    <p>
      <strong>{{ $t("invoiceBenefitsElectronicInvoicesTitle") }}</strong>
    </p>
    <ul>
      <li>{{ $t("invoiceBenefitsElectronicInvoicesL1") }}</li>
      <li>{{ $t("invoiceBenefitsElectronicInvoicesL2") }}</li>
      <li>{{ $t("invoiceBenefitsElectronicInvoicesL3") }}</li>
      <li>{{ $t("invoiceBenefitsElectronicInvoicesL4") }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "NoElectronicInvoiceTemplate"
};
</script>

<style scoped>
.button-orange {
  display: inline-block;
  width: auto;
  padding: 10px;
  font-size: 12px;
  background: #f49400;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin: 10px 5px;
  cursor: pointer;
}
</style>
